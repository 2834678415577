import { InlineWidget } from "react-calendly"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import PageIntro2 from "../components/PageIntro2"

const SchedulePage = ({ location }) => {
  return (
    <Layout location={location.pathname} navBarType="white">
      <Seo title="Contact Us" />
      <main className="w-full">
        <PageIntro2
          headline="Ready to get started?"
          text="Schedule your free 30 minutes information call to learn how Shopify can improve your business."
          height="60vh"
        />
        <InlineWidget
          url="https://calendly.com/chernandez-55/30min"
          styles={{ height: "900px", marginTop: "24px" }}
        />
      </main>
    </Layout>
  )
}

export default SchedulePage
